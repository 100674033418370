import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Store from 'store';

import { Modal, OpenModal } from '../../../componets/Modal';

import { URL_INICIO_JORNADA, URL_FIM_JORNADA, URL_SOLICITAR_CONTATO } from '../../../api';
import { Utils } from '../../../helpers';

import './scss/Home.scss';

/**
 * Home
 * 
 * Página que exibe as principais opções do site. 
 * 
 */
class Home extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false,
      latitude: "",
      longitude: "",
      loadingSubmit: false,
      solicitacaoMsg: ""
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  componentDidMount(){
    document.title = "QNET";
    this.setState({ show: true });

    Store.remove('msgLogin');
    Store.remove('respLogin');
  }

  /**
   * Método que exibe ou esconde o modal
   * @method toggleModal
   * @param { e } Object recebe o elemento clicado
   */
  toggleModal( e ){

    const { target } = e.currentTarget.dataset;

    this.setState({
      showModal: !this.state.showModal,
      targetModal: target
    });
    
  }

  /**
   * Método que auxilia na exibição das notificações de acordo com o parâmetro passado
   * @method exibeNotificacao
   * @param { errorSolicitacao } Boolean recebe verdadeiro ou falso
   * @param { msg } String recebe uma string com a mensagem retornada da api
   */
  exibeNotificacao( errorSoliciracao, msg ){

    setTimeout(() => { 
      this.setState({
        showModal: false, 
        loadingSubmit: false,  
        solicitacaoMsg: msg 
      });

      if( errorSoliciracao ){
        this.setState({ targetModal: "solicitacao-negada" });
      }
      else {
        this.setState({ targetModal: "solicitacao-aceita" });
      }
    }, 1000);

    setTimeout(() => {         
      this.setState({ showModal: true });
      document.body.removeAttribute("data-loading");
    }, 2000);
  }

  /**
   * Método que envia uma solicação para a API de acordo com a necessidade do usuário
   * @method onSubmit
   * @param { e } object recebe o elemento clicado
   */
   onSubmit( e ){
    e.preventDefault();

    const { targetModal } = this.state;
    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const latitude = Store.get('latitude');
    const longitude = Store.get('longitude');

    const post = {
      'email': dadosUsuario.email,
      'latitude': latitude,
      'longitude': longitude,
      'imei':UUID,
    };

    this.setState({ loadingSubmit: true });

    document.body.setAttribute("data-loading", true);

    //Trata o início da jornada
    if( targetModal === "iniciar-jornada" ){
      Utils.sendPost(post, URL_INICIO_JORNADA)
      .then(
        (response) => {
          if( response.status === 409 )
            this.props.history.push('/logout');

          this.exibeNotificacao( response.error, response.msg );
        }
      );
    }
    //Trata o fim da jornada
    if( targetModal === "finalizar-jornada" ){
      Utils.sendPost(post, URL_FIM_JORNADA)
      .then(
        (response) => {
          if( response.status === 409 )
            this.props.history.push('/logout');

          this.exibeNotificacao( response.error, response.msg );
        }
      );
    }
    //Trata uma solicitação de contato
    if( targetModal === "solicitar-contato" ){

      post["id_pendencia"] = 0;

      Utils.sendPost(post, URL_SOLICITAR_CONTATO)
      .then(
        (response) => {
          if( response.status === 409 )
            this.props.history.push('/logout');

          this.exibeNotificacao( response.error, response.msg );
        }
      );
    }

  }

  /**
   * Método que auxilia na renderização dos modais
   * @method renderContentModal
   */
  renderContentModal(){
    const { targetModal } = this.state;

    if( targetModal === "iniciar-jornada" ){
      return (
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title text-center">ATENÇÃO</h3>
            <span className="text text-center text-bold">Tem certeza que deseja reportar o início da jornada?</span>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full btn-dark" onClick={ this.toggleModal }>CANCELAR</button>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full" onClick={ (e) => this.onSubmit(e) }>ENVIAR</button>
          </div>
        </div>
      );
    }

    if( targetModal === "finalizar-jornada" ){
      return (
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title text-center">ATENÇÃO</h3>
            <span className="text text-center text-bold">Tem certeza que deseja reportar o finalizar jornada?</span>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full btn-dark" onClick={ this.toggleModal }>CANCELAR</button>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full" onClick={ (e) => this.onSubmit(e) }>ENVIAR</button>
          </div>
        </div>
      );
    }

    if( targetModal === "solicitar-contato" ){
      return (
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title text-center">ATENÇÃO</h3>
            <span className="text text-center text-bold">Tem certeza que deseja solicitar o contato?</span>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full btn-dark" onClick={ this.toggleModal }>CANCELAR</button>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full" onClick={ (e) => this.onSubmit(e) }>ENVIAR</button>
          </div>
        </div>
      );
    }

    if( targetModal === "solicitacao-negada" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title title-icon error text-center"><span className="icon icon-fechar"></span> ERRO</h3>
            <span className="text text-center text-bold">{ this.state.solicitacaoMsg }</span>
          </div>
        </div>
      );
    }

    if( targetModal === "solicitacao-aceita" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title title-icon sucesso text-center"><span className="icon icon-check"></span> Solicitação realizada.</h3>
            <span className="text text-center text-bold">{ this.state.solicitacaoMsg }</span>
          </div>
        </div>
      );
    }

  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {
    const classForm = this.state.loadingSubmit ?  "form loading-form" : "form";

    return (
      <>
        <section className={this.state.show ? "session session-home show" : "session session-home"}>
          <div className="container">
            <div className="opcoes-home">
              <div className="opcoes-inner">
                <ul className="list-acoes row align-items-center justify-content-center">
                  <li className="col-12 col-md-6">
                    <OpenModal className="inner" onOpenModal={(e) => this.toggleModal(e) } opTarget="iniciar-jornada">
                      <div className="info">
                        <span className="icon icon-inicio-jornada"></span>
                        <span className="text">Iniciar Jornada</span>
                      </div>
                    </OpenModal>
                  </li>
                  <li className="col-12 col-md-6">
                    <OpenModal className="inner" onOpenModal={(e) => this.toggleModal(e) } opTarget="finalizar-jornada">
                      <div className="info">
                        <span className="icon icon-fim-jornada"></span>
                        <span className="text">Finalizar Jornada</span>
                      </div>
                    </OpenModal>
                  </li>
                  <li className="col-12 col-md-4">
                    <Link to="/acoes/solicitar-desbloqueio" className="inner">
                      <div className="info">
                        <span className="icon icon-desbloquear"></span>
                        <span className="text">Solicitar Desbloqueio</span>
                      </div>
                    </Link>
                  </li>
                  <li className="col-12 col-md-4">
                    <OpenModal className="inner" onOpenModal={(e) => this.toggleModal(e) } opTarget="solicitar-contato">
                      <div className="info">
                        <span className="icon icon-fone"></span>
                        <span className="text">Solicitar Contato</span>
                      </div>
                    </OpenModal>
                  </li>
                  <li className="col-12 col-md-4">
                    <Link to="/checklist" className="inner">
                      <div className="info">
                        <span className="icon icon-celular"></span>
                        <span className="text">Checklist</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Modal id="modal-acoes" className="modal-small" show={ this.state.showModal } onClose={ this.toggleModal } refModal="iniciar-jornada">
          <div className={ classForm }>
            { this.renderContentModal() }
          </div>         
        </Modal>
      </>
    );
  }
}

export default Home;