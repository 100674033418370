import React, { Component } from 'react';

import Store from 'store';

import LogoQnet from '../../../images/logo-Qnet.png';
import LogoQuality from '../../../images/logo-Quality.png';

import { URL_TROCAR_SENHA } from '../../../api';
import { Utils } from '../../../helpers';

import './scss/Login.scss';

/**
 * AtualizarSenha
 * 
 * Página que atualizar a senha do usuário. 
 * 
 */
class AtualizarSenha extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false,
      novaSenha: '',
      confirmaSenha: '',
      error: false,
      message: '',
      btnHabilitado: true,
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  componentDidMount(){
    const el = this;

    document.title = "Atualizar senha | QNET";

    setTimeout(() => {
      el.setState({ show: true });
    }, 300);

  }

  /**
   * Método que envia uma solicação para a API para autenticar o usuário
   * @method onSubmit
   * @param { e } Object recebe o elemento clicado
   */
  onSubmit( e ) {
    e.preventDefault();

    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const { novaSenha } = this.state;

    const post = {
      'email': dadosUsuario.email,
      'senha': novaSenha,
      'imei':UUID,
    };

    // Verifica na API se o usuário é válido
    Utils.sendPost( post, URL_TROCAR_SENHA )
    .then(
      (response) => {
        if( response.error ) {
          this.setState({ error: true, message: response.messages });
        } else {          
          Store.set('msgLogin', "Senha atualizada com sucesso");
          Store.set('respLogin', 1);
          this.props.history.push('/logout');
        }
      }
    );

  }

  /**
   * Método que seta os states para o login e a senha, válida o e-mail e se 
   * senha é maior que 4 caracteres e habilita o botão enviar
   * @method handleChange
   * @param { e } Object recebe o elemento alterado no input
   */
  handleChange( e ) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);

    const { novaSenha, confirmarSenha } = this.refs;
      
    if( novaSenha.value.length >= 5 && confirmarSenha.value.length >= 5 
        && novaSenha.value === confirmarSenha.value ){
      this.setState({ btnHabilitado: false });
    }
    else {
      this.setState({ btnHabilitado: true, error: false  });
    }

  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {
    const { error } = this.state;

    return (
      <section className={this.state.show ? "session session-login show" : "session session-login"}>
        <div className="container">

          <div className="row align-items-center">
            <div className="col-12">
              <img src={ LogoQnet } className="logo-qnet image-fluid" alt="QNET" />
            </div>
            <div className="col-12">
              <form className="form" data-error={ error }  onSubmit={ (e) => this.onSubmit(e) }>

                <h3 className="subtitle-login text-center">Atualize sua senha</h3>

                { error && (
                  <div className="form-group">
                    <span className="text text-bold text-center text-error">Login ou a senha inválido.</span>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="nova-senha">Nova senha:</label>
                  <input 
                    type="password" 
                    className="form-control" 
                    name="novaSenha" 
                    id="novaSenha" 
                    ref="novaSenha" 
                    placeholder="Digite a sua senha" 
                    autoComplete="off" 
                    onChange={this.handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmar-atual">Confirmar senha:</label>
                  <input 
                    type="password" 
                    className="form-control" 
                    name="confirmarSenha" 
                    id="confirmarSenha" 
                    ref="confirmarSenha" 
                    placeholder="Digite a sua senha" 
                    autoComplete="off" 
                    onChange={this.handleChange} />
                </div>
                <div className="form-group">
                 <button type="submit" className="btn btn-full btn-login" disabled={this.state.btnHabilitado}>ATUALIZAR</button>
                </div>
              </form>

              <a href="http://www.qualityinteligencia.com.br/" target="_blank" rel="noopener noreferrer">
                <img src={ LogoQuality } className="logo-quality image-fluid" alt="Quality Inteligência" />
              </a>
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default AtualizarSenha;