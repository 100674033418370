import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

/**
 * Page404
 * 
 * Página que é exibida quando o usuário acessa uma página que não existe rota para ela.
 *  
 */
class Page404 extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false
    }
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  componentDidMount(){
    const el = this;

    document.title = "Página não encontrada | QNET";

    setTimeout(() => {
      el.setState({ show: true });
    }, 300);

  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {
    return (
      <section className={this.state.show ? "session session-404 show" : "session session-404"}>
        <div className="container">

          <h3>Página não encontrada</h3>

        </div>
      </section>
    );
  }
}

export default Page404;