//import $ from 'jquery';
//import Popper from 'popper.js';
import "bootstrap/dist/js/bootstrap.bundle.min";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { BrowserRouter } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

// import { inicializarFirebase, permitirNotificacoes } from './push-notification';

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root")
);
// inicializarFirebase();
// permitirNotificacoes();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
