import React, { Component } from 'react';

import './scss/Combobox.scss'; 

/**
 * Combobox
 * 
 * Component que cria um select customizado. 
 * 
 */
export class Combobox extends Component {

  /**
   * Método que renderiza o componente combobox
   * @method render
   */
  render() {
    const selected = this.props.comboSelected;
    
    return (
      <div id={ this.props.id } className={ this.props.showCombox ? "custom-combobox show-combobox" : "custom-combobox" }>
        <span className="main-select" 
          value={ selected.value } onClick={ this.props.onCombobox }>{ selected.label }</span>
        <ul>
          { this.props.children }
        </ul>
      </div>
    );
  }
}

/**
 * ComboboxItem
 * 
 * Component que cria um item para ser inserido no combobox.
 * 
 */
export class ComboboxItem extends Component { 

  /**
   * Método que renderiza um item no componente combobox
   * @method render
   */
  render() {

    return (
      <li data-value={ this.props.value } 
        data-selected={ this.props.selected } 
        onClick={ this.props.onSelectItem }>
        { this.props.label }
      </li>
    );
  }
}