import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Store from 'store';

import LogoQnet from '../../../images/logo-Qnet.png';
import LogoQuality from '../../../images/logo-Quality.png';

import { URL_ESQUECEU_SENHA } from '../../../api';
import { Utils } from '../../../helpers';

import './scss/Login.scss';

import uuidv1 from 'uuid/v1';

/**
 * EsqueciMinhaSenha
 * 
 * Página que solicitar uma nova senha para o usuário. 
 * 
 */
class EsqueciMinhaSenha extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false,
      login: '',
      error: false,
      message: '',
      btnHabilitado: true,
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  componentDidMount(){
    const el = this;

    document.title = "Esqueci minha senha | QNET";

    setTimeout(() => {
      el.setState({ show: true });
    }, 300);

  }

  /**
   * Método que envia uma solicação para a API para autenticar o usuário
   * @method onSubmit
   * @param { e } Object recebe o elemento clicado
   */
  onSubmit( e ) {
    e.preventDefault();

    let UUID = Store.get('UUID');

    if( UUID === undefined ){
      UUID = uuidv1();
      Store.set('UUID', UUID);
    }
    
    const { login } = this.state;

    const post = {
      'email':login
    };

    // Verifica na API se o usuário é válido
    Utils.sendPost( post, URL_ESQUECEU_SENHA )
    .then(
      (response) => {
        if( response.error ) {
          this.setState({ error: true, message: response.messages });
        } else {
          this.setState({ error: false });
          Store.set('msgLogin', "Sua solicitação foi efetuada");
          Store.set('respLogin', 0);
          this.props.history.push('/login');
        }
      }
    );
  }

  /**
   * Método que seta os states para o login e a senha, válida o e-mail e se 
   * senha é maior que 4 caracteres e habilita o botão enviar
   * @method handleChange
   * @param { e } Object recebe o elemento alterado no input
   */
  handleChange( e ) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);

    const regexEmail = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const { login } = this.refs;
      
    if( regexEmail.test(login.value)){
      this.setState({ btnHabilitado: false });
    }
    else {
      this.setState({ btnHabilitado: true });
    }

  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {
    const { error, message } = this.state;

    return (
      <section className={this.state.show ? "session session-login show" : "session session-login"}>
        <div className="container">

          <div className="row align-items-center">
            <div className="col-12">
              <img src={ LogoQnet } className="logo-qnet image-fluid" alt="QNET" />
            </div>
            <div className="col-12">
              <form className="form" ref="form" data-error={ error }  onSubmit={ (e) => this.onSubmit(e) }>

                { error && message && (
                  <div className="form-group messages">
                    <span className="text text-bold text-center text-error">{ message }</span>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="login">Email:</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    name="login" 
                    id="login" 
                    ref="login" 
                    placeholder="Digite aqui seu email" 
                    autoComplete="off" 
                    onChange={this.handleChange} />
                </div>
                <div className="form-group">
                  <Link to="/login" className="esqueci-minha-senha text-right">Voltar ao login</Link>
                </div>
                <div className="form-group">
                 <button type="submit" className="btn btn-full btn-login" disabled={this.state.btnHabilitado}>SOLICITAR NOVA SENHA</button>
                </div>
              </form>

              <a href="http://www.qualityinteligencia.com.br/" target="_blank" rel="noopener noreferrer">
                <img src={ LogoQuality } className="logo-quality image-fluid" alt="Quality Inteligência" />
              </a>
            </div>
          </div>

        </div>
      </section>
    );
  }
}

export default EsqueciMinhaSenha;