import React, { Component } from "react";

import { NavLink, Link } from "react-router-dom";

import ScrollArea from "react-scrollbar";
import { Modal, OpenModal } from "../../componets/Modal";

import "./scss/Navbar.scss";

import LogoQnet from "../../images/logo-Qnet.png";

/**
 * Navbar
 *
 * Classe que renderiza o menu do site.
 *
 */
class Navbar extends Component {
    /**
     * Método que é executado quando a classe é carregada
     * @method constructor
     * @param { props } Object recebe as proiedades do componentes
     */
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showMenu: false,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.hideMemuResponsive = this.hideMemuResponsive.bind(this);
        this.toggleMemuResponsive = this.toggleMemuResponsive.bind(this);
    }

    /**
     * Método que exibe ou esconde o modal
     * @method toggleModal
     */
    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    /**
     * Método que exibe ou esconde o menu na versão responsiva
     * @method toggleMemuResponsive
     */
    toggleMemuResponsive() {
        this.setState({
            showMenu: !this.state.showMenu,
        });
    }

    /**
     * Método que esconde o menu na versão responsiva
     * @method hideMemuResponsive
     */
    hideMemuResponsive() {
        this.setState({
            showMenu: false,
        });
    }

    /**
     * Método que renderiza a página
     * @method render
     */
    render() {
        const classMenuResponsive = this.state.showMenu
            ? "navbar-nav bd-navbar-nav flex-row show"
            : "navbar-nav bd-navbar-nav flex-row";
        const classBtnMenuResponsive = this.state.showMenu
            ? "view-menu-sm show"
            : "view-menu-sm";

        return (
            <>
                <nav className="navbar">
                    <div className="container">
                        <div
                            className="logo-qnet"
                            onClick={this.hideMemuResponsive}
                        >
                            <NavLink to="/">
                                <img
                                    src={LogoQnet}
                                    className="image-fluid"
                                    alt="QNET"
                                />
                            </NavLink>
                        </div>
                        <ul className={classMenuResponsive}>
                            <li onClick={this.hideMemuResponsive}>
                                <NavLink to="/quality-com-voce">
                                    Quality com você
                                </NavLink>
                            </li>
                            <li>Quality Data</li>
                            <li>Pendências</li>
                            <li onClick={this.hideMemuResponsive}>
                                <NavLink to="/checklist">Checklist</NavLink>
                            </li>
                            <li>Histórico Eventos</li>
                            <li onClick={this.hideMemuResponsive}>
                                <NavLink to="/logout">Logout</NavLink>
                            </li>
                        </ul>

                        <div
                            className={classBtnMenuResponsive}
                            onClick={this.toggleMemuResponsive}
                        >
                            <span></span>
                        </div>
                    </div>
                </nav>
                <Modal
                    id="modal-notificacoes"
                    className="modal-medium"
                    show={this.state.showModal}
                    onClose={this.toggleModal}
                >
                    <h3 className="title text-center">Notificações</h3>
                    <ScrollArea
                        speed={0.8}
                        className="area"
                        contentClassName="content"
                        horizontal={false}
                    >
                        <ul className="list-notificacoes">
                            <li onClick={this.hideMemuResponsive}>
                                <Link
                                    to="/quality-com-voce/detalhes/ocorrencias/120"
                                    onClick={(e) => this.toggleModal(e)}
                                >
                                    <span className="text text-bold">
                                        QUALITY INTELIGÊNCIA - Perda de energia
                                        Smartbox
                                    </span>
                                    <span className="text">
                                        Perda de energia detectada na smartbox
                                        SB_NOVA do cliente QUALITY INTELIGÊNCIA
                                    </span>
                                </Link>
                            </li>
                            <li onClick={this.hideMemuResponsive}>
                                <Link
                                    to="/quality-com-voce/detalhes/ocorrencias/120"
                                    onClick={(e) => this.toggleModal(e)}
                                >
                                    <span className="text text-bold">
                                        QUALITY INTELIGÊNCIA - Perda de energia
                                        Smartbox
                                    </span>
                                    <span className="text">
                                        Perda de energia detectada na smartbox
                                        SB_NOVA do cliente QUALITY INTELIGÊNCIA
                                    </span>
                                </Link>
                            </li>
                            <li onClick={this.hideMemuResponsive}>
                                <Link
                                    to="/quality-com-voce/detalhes/ocorrencias/120"
                                    onClick={(e) => this.toggleModal(e)}
                                >
                                    <span className="text text-bold">
                                        QUALITY INTELIGÊNCIA - Perda de energia
                                        Smartbox
                                    </span>
                                    <span className="text">
                                        Perda de energia detectada na smartbox
                                        SB_NOVA do cliente QUALITY INTELIGÊNCIA
                                    </span>
                                </Link>
                            </li>
                            <li onClick={this.hideMemuResponsive}>
                                <Link
                                    to="/quality-com-voce/detalhes/ocorrencias/120"
                                    onClick={(e) => this.toggleModal(e)}
                                >
                                    <span className="text text-bold">
                                        QUALITY INTELIGÊNCIA - Perda de energia
                                        Smartbox
                                    </span>
                                    <span className="text">
                                        Perda de energia detectada na smartbox
                                        SB_NOVA do cliente QUALITY INTELIGÊNCIA
                                    </span>
                                </Link>
                            </li>
                            <li onClick={this.hideMemuResponsive}>
                                <Link
                                    to="/quality-com-voce/detalhes/ocorrencias/120"
                                    onClick={(e) => this.toggleModal(e)}
                                >
                                    <span className="text text-bold">
                                        QUALITY INTELIGÊNCIA - Perda de energia
                                        Smartbox
                                    </span>
                                    <span className="text">
                                        Perda de energia detectada na smartbox
                                        SB_NOVA do cliente QUALITY INTELIGÊNCIA
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </ScrollArea>
                </Modal>
            </>
        );
    }
}

export default Navbar;
