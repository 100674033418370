import React, { Component } from 'react';

import './scss/Modal.scss'; 

/**
 * Modal
 * 
 * Component que cria o modal customizado. 
 */
export class Modal extends Component {

   /**
   * Método que renderiza o componente modal
   * @method render
   */
  render() {    

    const { show } = this.props;

    if ( ! show )
      return null;

    return (
      <>
        <div id={this.props.id} 
          className={ this.props.className ? 
            `modal-qnet show-modal-qnet ${ this.props.className }` : "modal-qnet show-modal-qnet"
          }>
          <button type="button" className="modal-qnet-close" onClick={ this.props.onClose }>
            <span className="icon icon-fechar"></span>
          </button>
          <div className="modal-qnet-inner">
            {this.props.children}
          </div>          
        </div>
        <div className="mask-modal-qnet"></div>
      </>
    );
  }
}

/**
 * OpenModal
 * 
 * Component que cria um link que abrir o modal. 
 */
export class OpenModal extends Component { 
  /**
   * Método que renderiza o componente botão que abre o modal
   * @method render
   */
  render() {
    return (
      <button type="button" className={ this.props.className } onClick={ this.props.onOpenModal } data-target={ this.props.opTarget }>{ this.props.children }</button>
    );
  }
}