import { Component } from "react";

import Store from "store";

/**
 * Logout
 *
 * Página onde o usuário faz o logout do site.
 *
 */
class Logout extends Component {
    /**
     * Método que é executado logo após o componete ser montado
     * @method componentDidMount
     */
    componentDidMount() {
        document.title = "Saindo... | QNET";

        Store.remove("loggedIn");
        Store.remove("dataUser");
        Store.remove("UUID");
        Store.remove("latitude");
        Store.remove("longitude");
        this.props.history.push("/login");
    }

    /**
     * Método que renderiza a página
     * @method render
     */
    render() {
        return null;
    }
}

export default Logout;
