/**
 * Utils
 * 
 * Classe helper onde os métodos que podem ser compartilhados entre os componentes ou páginas. 
 */
export class Utils {

    /**
   * Método static que busca dados de uma api via ajax
   * @method sendPost
   * @param { dataForm } Object recebe os dados de formulário
   * @param { URL } String recebe uma url para onde vai ser feita a solicitação
   */
    static sendPost = ( dataForm, URL ) => {
        const formData = new FormData();

        Object.entries( dataForm ).forEach(( [key, value] ) => {
            formData.append(key, value);
        });

        return fetch(URL, {
            method:'POST',
            body: formData
        })
        .then(res => res.json())
    }

}