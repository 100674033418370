/**
 * Arquivo que configura as rotas para API
 */

const baseUrl = "https://qnet.quality.com.vc/qclient";

export const URL_LOGIN = baseUrl + "/api/v1/login";
export const URL_ESQUECEU_SENHA = baseUrl + "/api/v1/esqueceu-senha";
export const URL_TROCAR_SENHA = baseUrl + "/api/v1/trocar-senha";

export const URL_LISTA_DESBOQUEIO = baseUrl + "/api/v1/lista-desbloqueio";
export const URL_LISTA_ROTEIROS = baseUrl + "/api/v1/lista-roteiros";
export const URL_LISTA_CHECKLIST =
    baseUrl + "/api/v1/lista-checklists-segmentado";
export const URL_LISTA_CLIENTES = baseUrl + "/api/v1/clientes";
export const URL_LISTA_OCORRENCIAS = baseUrl + "/api/v1/qcv/busca-ocorrencias";
export const URL_DETALHES_OCORRENCIAS = baseUrl + "/api/v1/qcv/ver-ocorrencia";
export const URL_ITEM_CHECKLIST =
    baseUrl + "/api/v1/itens-checklists-segmentado";
export const URL_ITEM_ROTEIRO = baseUrl + "/api/v1/itens-roteiro";
export const URL_SALVA_ROTEIRO = baseUrl + "/api/v1/salva-roteiro-multiplos";

export const URL_DESBOQUEIO =
    baseUrl + "/api/v1/alarme/solicitacao-desbloqueio";
export const URL_PANICO = baseUrl + "/api/v1/alarme/panico";
export const URL_INICIO_JORNADA = baseUrl + "/api/v1/alarme/inicio-jornada";
export const URL_FIM_JORNADA = baseUrl + "/api/v1/alarme/fim-jornada";
export const URL_SOLICITAR_CONTATO =
    baseUrl + "/api/v1/alarme/solicitacao-contato";
