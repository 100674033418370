import React, { Component } from 'react';

import { Modal, OpenModal } from '../Modal';

import Store from 'store';
import { Utils } from '../../helpers';

import './scss/Notificacao.scss'; 

/**
 * Notificacao
 * 
 * Component que exibe as notificações do site. 
 * 
 */
class Notificacao extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ) {
    super( props );

    this.state = {
      showModal: false,
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  /**
   * Método que exibe ou esconde o modal
   * @method toggleModal
   * @param { e } Object recebe o elemento clicado
   */
  toggleModal( e ){
    const { target } = e.currentTarget.dataset;

    this.setState({
      showModal: !this.state.showModal,
      targetModal: target
    });    
  }

  /**
   * Método que auxilia na exibição das notificações de acordo com o parâmetro passado
   * @method exibeNotificacao
   * @param { targetModal } String recebe o tipo de modal
   */
  exibeNotificacao( targetModal ){
    
  }

  /**
   * Método que envia uma solicação para a API de acordo com a necessidade do usuário
   * @method onSubmit
   * @param { e } Object recebe o elemento clicado
   */
  onSubmit( e ) {
    e.preventDefault();
  }

  /**
   * Método que auxilia na renderização do modal
   * @method renderContentModal
   */
  renderContentModal(){
    const { targetModal } = this.state;

    if( targetModal === "video" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3>Modal de vídeos</h3>
          </div>
        </div>
      );
    }

  }  

  /**
   * Método que renderiza o componente
   * @method render
   */
  render() {
    const classModal = this.state.loadingSubmit ?  "modal-notificacoes loading-modal" : "modal-notificacoes";

    return (
      <>
        <Modal id="modal-notificacoes" className={ classModal } show={ this.state.showModal } onClose={ this.toggleModal }>
          { this.renderContentModal() }
        </Modal>
      </>
    );
  }
}

export default Notificacao;