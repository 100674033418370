import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import ScrollArea from 'react-scrollbar';
import { Player } from 'video-react';

import Store from 'store';
import { URL_DETALHES_OCORRENCIAS } from '../../../api';
import { Utils } from '../../../helpers';

import moment from 'moment';

import './scss/QualityComVoce.scss';
import './scss/Detalhes.scss';

import { Modal, OpenModal } from '../../../componets/Modal';

import ImageSemOcorrencia from '../../../images/semOcorrencias.png';

/**
 * Detalhes
 * 
 * Página que exibe o detalhe da ocorrência.
 * 
 */
class Detalhes extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false,
      showModal: false,
      loadingPage: true,
      nomeCliente: "",
      detalhesOcorrencias: [],
      dataOcorrencia: "",
      videoPoster: "",
      videoSrc: ""
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  async componentDidMount(){
    document.title = "Detalhes Ocorrências - Quality com você | QNET";

    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const { match: { params } } = this.props; 

    const post = {
      'email': dadosUsuario.email,
      'senha': dadosUsuario.senha,
      'id_ocorrencia': params.id,
      'imei': UUID,
    };

    // Busca na API os detalhes da ocorrência
    await Utils.sendPost( post, URL_DETALHES_OCORRENCIAS )
    .then(
      (response) => {
        
        if( response.status === 409 ){
          this.props.history.push('/logout');
          return;
        }
        
        if( response.status !== 200 ){
          this.setState({status: response.status, loadingPage: false, show: true})
          return;
        }

        const linkData = moment( response.ocorrencia.dh_ocorrencia ).format("YYYY-MM-DD")
        const arrDetalhes = response.ocorrencia;
        arrDetalhes.dh_ocorrencia = moment( arrDetalhes.dh_ocorrencia ).format("DD/MM/YYYY [às] HH:mm");
        arrDetalhes.dh_registro = moment( arrDetalhes.dh_registro ).format("DD/MM/YYYY [às] HH:mm");

        this.setState({ 
          nomeCliente: response.ocorrencia.nome_cliente,
          detalhesOcorrencias: response.ocorrencia,
          dataOcorrencia: linkData,
          videoPoster: response.ocorrencia.nome_video !== false ? response.ocorrencia.nome_video.replace('.mp4', '.jpg') : "",
          videoSrc: response.ocorrencia.nome_video !== false ? response.ocorrencia.nome_video : "",
          show: true,
          loadingPage: false
        });

      }
    );

  }

  /**
   * Método que exibe ou esconde o modal
   * @method toggleModal
   * @param { e } Object recebe o elemento clicado
   */
  toggleModal( e ){    
    this.setState({
      showModal: !this.state.showModal
    });
    
  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {

    const { loadingPage, nomeCliente, detalhesOcorrencias, videoPoster, videoSrc } = this.state;

    if( loadingPage ){
      return (
        <section className={this.state.show ? "session session-quality-com-voce session-detalhes show" : "session session-quality-com-voce session-detalhes"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS</h3>
                  <div className="nav-opcoes">
                    <Link to={`/quality-com-voce/ocorrencias/${detalhesOcorrencias.id_cliente}/${ this.state.dataOcorrencia }`} className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                    <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                  </div>
                </header>                   
                <div className="modal-inner"></div>
                <div className="spinner"></div>
              </div>
          </div>
        </section>
      );
    }

    if( detalhesOcorrencias.length === 0 ){
      return (
        <section className={this.state.show ? "session session-quality-com-voce session-detalhes show" : "session session-quality-com-voce session-detalhes"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS</h3>
                  <div className="nav-opcoes">
                    <Link to={`/quality-com-voce/ocorrencias/${detalhesOcorrencias.id_cliente}/${ this.state.dataOcorrencia }`} className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                    <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                  </div>
                </header>                   
                <div className="modal-inner">
                  <div className="sem-ocorrencias">
                      <img src={ ImageSemOcorrencia } className="img-fluid" alt="Não existe ocorrências" />
                      <p className="text text-center">Não existe ocorrências</p>
                  </div>
                </div>
              </div>
          </div>
        </section>
      );
    }

    return (
      <section className={this.state.show ? "session session-quality-com-voce session-detalhes show" : "session session-quality-com-voce session-detalhes"}>
        <div className="container">
            <div className="modal-custom">
              <header className="modal-heading">
                <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS<span className="nome-cliente">/ { nomeCliente }</span></h3>
                <div className="nav-opcoes">
                  <Link to={`/quality-com-voce/ocorrencias/${detalhesOcorrencias.id_cliente}/${ this.state.dataOcorrencia }`} className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                  <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                </div>
              </header>                   
              <div className="modal-inner">
                <ScrollArea
                  speed={0.8}
                  className="area"
                  contentClassName="content"
                  horizontal={false}>
                    
                   <div className="detalhes-info">
                      <ul>
                        <li>
                          <span className="text-label text-bold">{ detalhesOcorrencias.texto_ocorrencia }</span>
                          <span className="text-value text-bold">{ detalhesOcorrencias.id_ocorrencia }</span>
                        </li>
                        <li>
                          <span className="text-label">Ocorrência:</span>
                          <span className="text-value">{ detalhesOcorrencias.dh_ocorrencia }</span>
                        </li>
                        <li>
                          <span className="text-label">Registro:</span>
                          <span className="text-value">{ detalhesOcorrencias.dh_registro }</span>
                        </li>

                        {detalhesOcorrencias.itensRoteiro.map((item, key) => (
                          <li key={ key }>
                            <span className="text-label">{ item.texto_item_roteiro }</span>
                            <span className="text-value">{ item.texto_adicional }</span>
                          </li>
                        ))}

                        { videoSrc !== "" && (
                          <li>
                            <span className="text-label text-label-video">Vídeos:</span>
                            <div className="list-videos">
                              <OpenModal onOpenModal={(e) => this.toggleModal(e) }>
                                <div className="thumb" 
                                  style={{ backgroundImage: `url(${ videoPoster })` }} 
                                  poster={ videoPoster }
                                  video={ videoSrc }></div>
                              </OpenModal>
                            </div>
                          </li>
                        )}

                      </ul>
                   </div>

                </ScrollArea>
              </div>
            </div>
        </div>
        { videoSrc !== "" && (
          <Modal id="modal-videos" className="modal-videos" show={ this.state.showModal } onClose={ this.toggleModal }>
              <div className="embed-container">
                <Player
                  playsInline
                  fluid={ false }
                  autoPlay 
                  poster={ videoPoster }
                  src={ videoSrc }
                />
              </div>
          </Modal>
         )}
      </section>
    );
  }
}

export default Detalhes;