import React, { Component } from 'react';

import { Modal, OpenModal } from '../Modal';

import Store from 'store';
import { URL_PANICO } from '../../api';
import { Utils } from '../../helpers';

import './scss/BotaoPanico.scss'; 

/**
 * BotaoPanico
 * 
 * Component que exibe um botão onde pode ser feita uma solicitão de emegencia. 
 * 
 */
class BotaoPanico extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ) {
    super( props );

    this.state = {
      showModal: false,
      loadingSubmit: false,
      solicitacaoMsg: ""
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  /**
   * Método que exibe ou esconde o modal
   * @method toggleModal
   */
  toggleModal( e ){
    const { target } = e.currentTarget.dataset;

    this.setState({
      showModal: !this.state.showModal,
      targetModal: target
    });    
  }

  /**
   * Método que auxilia na exibição das notificações de acordo com o parâmetro passado
   * @method exibeNotificacao
   * @param { errorSolicitacao } Boolean recebe verdadeiro ou falso
   * @param { msg } String recebe uma string com a mensagem retornada da api
   */
  exibeNotificacao( errorSolicitacao, msg ){
    const el = this;

    setTimeout(() => { 
      this.setState({
        showModal: false, 
        loadingSubmit: false,  
        solicitacaoMsg: msg 
      });

      if( errorSolicitacao ){
        this.setState({ targetModal: "solicitacao-negada" });
      }
      else {
        this.setState({ targetModal: "solicitacao-aceita" });
      }
    }, 1000);

    setTimeout(() => {         
      el.setState({ showModal: true });
      document.body.removeAttribute("data-loading");
    }, 2000);
  }

  /**
   * Método que envia uma solicação para a API de acordo com a necessidade do usuário
   * @method onSubmit
   * @param { e } Object recebe o elemento clicado
   */
  onSubmit( e ) {
    e.preventDefault();
    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const latitude = Store.get('latitude');
    const longitude = Store.get('longitude');

    const post = {
      'email': dadosUsuario.email,
      'latitude': latitude,
      'longitude': longitude,
      'imei':UUID,
    };

    this.setState({ loadingSubmit: true });

    document.body.setAttribute("data-loading", true);
   
    //Faz uma solitação na API no alarme de pânico
    Utils.sendPost(post, URL_PANICO)
    .then(
      (response) => {
        if( response.status === 409 )
          this.props.history.push('/logout');

        this.exibeNotificacao( response.error, response.msg );
      }
    );

  }

  /**
   * Método que auxilia na renderização do modal
   * @method renderContentModal
   */
  renderContentModal(){
    const { targetModal } = this.state;

    if( targetModal === "solicitar-panico" ){
      return (
        <div className="row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title text-center">PÂNICO</h3>
            <span className="text text-center text-bold">Tem certeza que deseja acionar o botão de pânico?</span>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full btn-dark" onClick={ this.toggleModal }>CANCELAR</button>
          </div>
          <div className="col-12 col-md-5">
            <button type="button" className="btn btn-full btn-lighter" onClick={ (e) => this.onSubmit(e) }>CONFIRMAR</button>
          </div>
        </div>
      );
    }

    if( targetModal === "solicitacao-negada" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title title-icon error text-center"><span className="icon icon-fechar"></span> ERRO</h3>
            <span className="text text-center text-bold">{ this.state.solicitacaoMsg }</span>
          </div>
        </div>
      );
    }

    if( targetModal === "solicitacao-aceita" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title title-icon sucesso text-center"><span className="icon icon-check"></span> Solicitação realizada.</h3>
            <span className="text text-center text-bold">{ this.state.solicitacaoMsg }</span>
          </div>
        </div>
      );
    }

  }  

  /**
   * Método que renderiza o componente
   * @method render
   */
  render() {
    const classModal = this.state.loadingSubmit ?  "modal-small modal-panico loading-modal" : "modal-small modal-panico";

    return (
      <>
        <div className="container">
          <OpenModal className="btn btn-panico" onOpenModal={(e) => this.toggleModal(e) } opTarget="solicitar-panico">
            <span className="icon icon-alerta"></span>
            <span className="text">PÂNICO</span>
            <span className="icon icon-alerta"></span>
          </OpenModal>
        </div>
        <Modal id="botao-panico" className={ classModal } show={ this.state.showModal } onClose={ this.toggleModal }>
          { this.renderContentModal() }
        </Modal>
      </>
    );
  }
}

export default BotaoPanico;