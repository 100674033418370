import React, { Component } from "react";
import { Link } from "react-router-dom";

import Store from "store";

import LogoQnet from "../../../images/logo-Qnet.png";
import LogoQuality from "../../../images/logo-Quality.png";

import { URL_LOGIN } from "../../../api";
import { Utils } from "../../../helpers";

import "./scss/Login.scss";

import uuidv1 from "uuid/v1";

/**
 * Login
 *
 * Página responsável por autenticar o usuário.
 *
 */
class Login extends Component {
    /**
     * Método que é executado quando a classe é carregada
     * @method constructor
     * @param { props } Object recebe as proiedades do componentes
     */
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            login: "",
            senha: "",
            error: false,
            msgLogin: "",
            btnHabilitado: true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * Método que é executado logo após o componete ser montado
     * @method componentDidMount
     */
    componentDidMount() {
        const el = this;
        const msgLogin = Store.get("msgLogin");

        document.title = "Login | QNET";

        setTimeout(() => {
            el.setState({ show: true });
        }, 300);

        if (msgLogin) {
            el.setState({ msgLogin: msgLogin });
        }

        setTimeout(() => {
            Store.remove("msgLogin");
            Store.remove("respLogin");
        }, 5000);
    }

    /**
     * Método que envia uma solicação para a API para autenticar o usuário
     * @method onSubmit
     * @param { e } Object recebe o elemento clicado
     */
    onSubmit(e) {
        e.preventDefault();

        let UUID = Store.get("UUID");

        if (UUID === undefined) {
            UUID = uuidv1();
            Store.set("UUID", UUID);
        }

        const { login, senha } = this.state;

        const post = {
            email: login,
            senha: senha,
            imei: UUID,
            "gcm-token": "12345",
        };

        // Verifica na API se o usuário é válido
        Utils.sendPost(post, URL_LOGIN).then((response) => {
            if (response.error) {
                this.setState({ error: true });
                Store.remove("msgLogin");
                Store.remove("respLogin");
            } else {
                Store.set("loggedIn", true);
                Store.set("dataUser", response);

                //Recupera a latitude e longitude referente a sua localização
                navigator.geolocation.getCurrentPosition((location) => {
                    Store.set("latitude", location.coords.latitude);
                    Store.set("longitude", location.coords.longitude);
                });

                this.props.history.push("/");
            }
        });
    }

    /**
     * Método que seta os states para o login e a senha, válida o e-mail e se
     * senha é maior que 4 caracteres e habilita o botão enviar
     * @method handleChange
     * @param { e } Object recebe o elemento alterado no input
     */
    handleChange(e) {
        let change = {};
        change[e.target.name] = e.target.value;
        this.setState(change);

        const regexEmail =
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        const { login, senha } = this.refs;

        if (regexEmail.test(login.value) && senha.value.length >= 5) {
            this.setState({ btnHabilitado: false });
        } else {
            this.setState({ btnHabilitado: true });
        }
    }

    /**
     * Método que renderiza a página
     * @method render
     */
    render() {
        const { error, msgLogin } = this.state;
        const messageError = "Login ou a senha inválido.";
        const respLogin =
            Store.get("respLogin") === 1
                ? "Faça seu login"
                : "Verifique seu e-mail";

        return (
            <section
                className={
                    this.state.show
                        ? "session session-login show"
                        : "session session-login"
                }
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <img
                                src={LogoQnet}
                                className="logo-qnet image-fluid"
                                alt="QNET"
                            />
                        </div>
                        <div className="col-12">
                            <form
                                className="form"
                                data-error={error}
                                onSubmit={(e) => this.onSubmit(e)}
                            >
                                {error && (
                                    <div className="form-group messages">
                                        <span className="text text-bold text-center text-error">
                                            {messageError}
                                        </span>
                                    </div>
                                )}

                                {msgLogin && !error && (
                                    <div className="form-group messages">
                                        <span className="text text-bold text-center text-success">
                                            {msgLogin}
                                        </span>
                                        <span className="text text-center text-success">
                                            {respLogin}
                                        </span>
                                    </div>
                                )}

                                <div className="form-group">
                                    <label htmlFor="login">Email:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="login"
                                        id="login"
                                        ref="login"
                                        placeholder="Digite aqui seu email"
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="senha">Senha:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="senha"
                                        id="senha"
                                        ref="senha"
                                        placeholder="Digite a sua senha"
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <Link
                                        to="/login/esqueci-minha-senha"
                                        className="esqueci-minha-senha text-right"
                                    >
                                        Esqueci minha senha
                                    </Link>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-full btn-login"
                                        disabled={this.state.btnHabilitado}
                                    >
                                        ENTRAR
                                    </button>
                                </div>
                            </form>

                            <a
                                href="http://www.qualityinteligencia.com.br/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={LogoQuality}
                                    className="logo-quality image-fluid"
                                    alt="Quality Inteligência"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;
