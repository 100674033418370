import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Store from "store";

import Home from "./layout/Pages/Home";
import QualityComVoce from "./layout/Pages/QualityComVoce";
import Ocorrencias from "./layout/Pages/QualityComVoce/Ocorrencias";
import OcorrenciasDetalhes from "./layout/Pages/QualityComVoce/Detalhes";
import SolicitarDesbloqueio from "./layout/Pages/Acoes/SolicitarDesbloqueio";
import Checklist from "./layout/Pages/Checklist";
import ChecklistDetalhes from "./layout/Pages/Checklist/Detalhes";
import Logout from "./layout/Pages/Logout";

import Login from "./layout/Pages/Login";
import EsqueciMinhaSenha from "./layout/Pages/Login/EsqueciMinhaSenha";
import AtualizarSenha from "./layout/Pages/Login/AtualizarSenha";
import Page404 from "./layout/Pages/Page404";

import Navbar from "./layout/Navbar";
import BotaoPanico from "./componets/BotaoPanico";
import Notificacao from "./componets/Notificacao";

/**
 * Routes
 *
 * Classe que configura todas as rotas do site, cada rota é lincada a uma página.
 *
 */
class Routes extends Component {
    /**
     * Método que renderiza todas as rotas das páginas
     * @method render
     */
    render() {
        const loggedIn = Store.get("loggedIn");
        const { solicitacao_senha } =
            Store.get("dataUser") !== undefined ? Store.get("dataUser") : "N";

        if (loggedIn && solicitacao_senha === "N") {
            return (
                <>
                    <Navbar />
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route
                            exact
                            path="/quality-com-voce"
                            component={QualityComVoce}
                        />
                        <Route
                            path="/quality-com-voce/ocorrencias/:id_clinete/:data"
                            component={Ocorrencias}
                        />
                        <Route
                            path="/quality-com-voce/detalhes/ocorrencias/:id"
                            component={OcorrenciasDetalhes}
                        />
                        <Route
                            path="/acoes/solicitar-desbloqueio"
                            component={SolicitarDesbloqueio}
                        />
                        <Route exact path="/checklist" component={Checklist} />
                        <Route
                            path="/checklist/detalhes/:item/:id"
                            component={ChecklistDetalhes}
                        />
                        <Route path="/logout" component={Logout} />
                        <Route component={Page404} />
                    </Switch>
                    <BotaoPanico />
                    <Notificacao />
                </>
            );
        } else if (loggedIn && solicitacao_senha === "S") {
            return (
                <Switch>
                    <Route
                        path="/login/atualizar-senha"
                        component={AtualizarSenha}
                    />
                    <Route path="/logout" component={Logout} />
                    <Redirect to="/login/atualizar-senha" />
                </Switch>
            );
        } else {
            return (
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route
                        path="/login/esqueci-minha-senha"
                        component={EsqueciMinhaSenha}
                    />
                    <Redirect to="/login" />
                </Switch>
            );
        }
    }
}

export default Routes;
