import React, { Component } from "react";

import { Link } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";

import moment from "moment";

import Store from "store";

import { URL_LISTA_CLIENTES } from "../../../api";

import { Utils } from "../../../helpers";

import { Combobox, ComboboxItem } from "../../../componets/Combobox";

import "react-datepicker/dist/react-datepicker.css";
import "./scss/QualityComVoce.scss";

registerLocale("pt-BR", pt);

/**
 * QualityComVoce
 *
 * Página onde usuário pode filtrar ocorrências de acordo com empresa e uma data específica.
 *
 */
class QualityComVoce extends Component {
    /**
     * Método que é executado quando a classe é carregada
     * @method constructor
     * @param { props } Object recebe as proiedades do componentes
     */
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showCombobox: false,
            listaEmpresa: [],
            empresaSelect: [],
            dataFiltro: new Date(),
        };

        this.toggleCombobox = this.toggleCombobox.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * Método que é executado logo após o componete ser montado
     * @method componentDidMount
     */
    async componentDidMount() {
        document.title = "Quality com você | QNET";

        const dadosUsuario = Store.get("dataUser");
        const UUID = Store.get("UUID");
        const clientes = [];

        const post = {
            email: dadosUsuario.email,
            imei: UUID,
        };

        clientes.push({
            id_cliente: dadosUsuario.id_cliente,
            nome_cliente: dadosUsuario.empresa,
        });

        //Busca uma lista dos clientes na API para o usuário logado
        Utils.sendPost(post, URL_LISTA_CLIENTES).then((response) => {
            if (response.status === 409) {
                this.props.history.push("/logout");
                return;
            }

            if (response.error === true) return;

            response.data.forEach((element) => {
                clientes.push({
                    id_cliente: element.id_cliente,
                    nome_cliente: element.nome_cliente,
                });
            });
            this.setState({ listaEmpresa: clientes });
        });

        this.setState({
            show: true,
            empresaSelect: {
                value: dadosUsuario.id_cliente,
                label: dadosUsuario.empresa,
            },
        });
    }

    /**
     * Método que seta state dataFiltro do campo selecionado na data
     * @method handleChange
     * @param { date } String recebe uma data
     */
    handleChange(date) {
        console.log(date);
        this.setState({
            dataFiltro: date,
        });
    }

    /**
     * Método que exibe ou esconde o combobox
     * @method toggleCombobox
     */
    toggleCombobox() {
        this.setState({
            showCombobox: !this.state.showCombobox,
        });
    }

    /**
     * Método que seleciona um item do combobox quando clicado
     * @method selectItem
     * @param { value } String recebe o value do elemento clicado
     * @param { label } String recebe o label do elemento clitado
     */
    selectItem(value, label) {
        const selected = {
            value: value,
            label: label,
        };

        this.setState({
            empresaSelect: selected,
            showCombobox: false,
        });
    }

    /**
     * Método que envia o usuário para uma rota de acordo a correncia selecionada
     * @method onSubmit
     * @param { e } Object recebe o elemento clicado
     */
    onSubmit(e) {
        e.preventDefault();

        const empresa = this.state.empresaSelect.value;
        const dataFiltro = moment(this.state.dataFiltro).format("YYYY-MM-DD");
        const urlSearch = `/quality-com-voce/ocorrencias/${empresa}/${dataFiltro}`;

        this.props.history.push(urlSearch);
    }

    /**
     * Método que renderiza a página
     * @method render
     */
    render() {
        const { dataFiltro } = this.state;
        console.log(dataFiltro);
        const dataAtual =
            dataFiltro !== null
                ? moment(dataFiltro).format("DD/MM/YYYY")
                : "Não informada";

        return (
            <section
                className={
                    this.state.show
                        ? "session session-quality-com-voce show"
                        : "session session-quality-com-voce"
                }
            >
                <div className="container">
                    <div className="modal-custom">
                        <header className="modal-heading">
                            <h3 className="modal-title">
                                <span className="icon icon-ocorrencias"></span>{" "}
                                OCORRÊNCIAS
                            </h3>
                            <Link to="/" className="link-modal home">
                                <span className="icon icon-fechar"></span>
                            </Link>
                        </header>
                        <div className="modal-inner">
                            <form
                                className="form"
                                onSubmit={(e) => this.onSubmit(e)}
                            >
                                <div className="row justify-content-between align-items-center">
                                    <div className="from-group col-12 col-md-7">
                                        <label className="legend text-bold">
                                            Selecione a empresa:
                                        </label>
                                        <Combobox
                                            id="empresa"
                                            onCombobox={this.toggleCombobox}
                                            showCombox={this.state.showCombobox}
                                            comboSelected={
                                                this.state.empresaSelect
                                            }
                                        >
                                            {this.state.listaEmpresa.map(
                                                (item, key) => (
                                                    <ComboboxItem
                                                        key={key}
                                                        value={item.id_cliente}
                                                        label={
                                                            item.nome_cliente
                                                        }
                                                        selected={
                                                            this.state
                                                                .empresaSelect
                                                                .value ===
                                                            item.id_cliente
                                                                ? "true"
                                                                : "false"
                                                        }
                                                        onSelectItem={() =>
                                                            this.selectItem(
                                                                item.id_cliente,
                                                                item.nome_cliente
                                                            )
                                                        }
                                                    />
                                                )
                                            )}
                                        </Combobox>
                                    </div>
                                    <div className="from-group col-12 col-md-5">
                                        <label className="legend text-right">
                                            Data: {dataAtual}
                                        </label>
                                        <div className="custom-search">
                                            <DatePicker
                                                className="form-control form-date"
                                                id="dataFiltro"
                                                autoComplete="off"
                                                locale="pt-BR"
                                                dateFormat="dd/MM/yyyy"
                                                multidate="true"
                                                maxDate={new Date()}
                                                selected={dataFiltro}
                                                onSelect={this.handleChange}
                                                placeholderText="Filtre por data"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                            <label
                                                className="btn-search"
                                                htmlFor="dataFiltro"
                                            >
                                                <span className="icon icon-buscar"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="from-group col-12 col-md-12">
                                        <button
                                            type="submit"
                                            disabled={
                                                dataFiltro === null
                                                    ? "disabled"
                                                    : ""
                                            }
                                            className="btn btn-full"
                                        >
                                            VER OCORRÊNCIAS
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default QualityComVoce;
