import React, { Component } from "react";

import { Link } from "react-router-dom";

import Store from "store";

import { Combobox, ComboboxItem } from "../../../componets/Combobox";
import { Modal } from "../../../componets/Modal";

import { URL_LISTA_DESBOQUEIO, URL_DESBOQUEIO } from "../../../api";
import { Utils } from "../../../helpers";

import "./scss/Acoes.scss";
import "./scss/SolicitarDesbloqueio.scss";

/**
 * SolicitarDesbloqueio
 *
 * Página onde o usuário pode solicitar um desbloqueio para uma porta epecifica.
 *
 */
class SolicitarDesbloqueio extends Component {
    /**
     * Método que é executado quando a classe é carregada
     * @method constructor
     * @param { props } Object recebe as proiedades do componentes
     */
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            showModal: false,
            showCombobox: false,
            portaoSelected: {
                value: "",
                label: "Selecione",
            },
            loadingSubmit: false,
            listDesbloqueios: [],
        };

        this.toggleCombobox = this.toggleCombobox.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    /**
     * Método que é executado logo após o componete ser montado
     * @method componentDidMount
     */
    async componentDidMount() {
        document.title = "Solicitar Desbloqueio - Ações | QNET";
        this.setState({ show: true });

        const dadosUsuario = Store.get("dataUser");
        const UUID = Store.get("UUID");

        const post = {
            email: dadosUsuario.email,
            senha: dadosUsuario.senha,
            imei: UUID,
        };

        //Busca na API uma lista com os desboqueios de acordo com o usuário logado
        await Utils.sendPost(post, URL_LISTA_DESBOQUEIO).then((response) => {
            if (response.status === 409) {
                this.props.history.push("/logout");
                return;
            }

            if (response.error === true) {
                return;
            }

            console.log();

            if (
                response.lista_desbloqueio &&
                typeof response.lista_desbloqueio["grupo"] !== "undefined"
            ) {
                this.setState({
                    listDesbloqueios:
                        response.lista_desbloqueio["grupo"][0]["empresa"][0][
                            "desbloqueios"
                        ],
                });
            }
        });
    }

    /**
     * Método que exibe ou esconde o combobox
     * @method toggleCombobox
     */
    toggleCombobox() {
        this.setState({
            showCombobox: !this.state.showCombobox,
        });
    }

    /**
     * Método que seleciona um item do combobox quando clicado
     * @method selectItem
     * @param { value } String recebe o value do elemento clicado
     * @param { label } String recebe o label do elemento clicado
     */
    selectItem(value, label) {
        const selected = {
            value: value,
            label: label,
        };

        this.setState({
            portaoSelected: selected,
            showCombobox: false,
        });
    }

    /**
     * Método que exibe ou esconde o modal
     * @method toggleModal
     */
    toggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    /**
     * Método que auxilia na exibição das notificações de acordo com o parâmetro passado
     * @method exibeNotificacao
     * @param { errorSolicitacao } Boolean recebe verdadeiro ou falso
     * @param { msg } String recebe uma string com a mensagem retornada da api
     */
    exibeNotificacao(errorSolicitacao, msg) {
        if (errorSolicitacao) {
            this.setState({ targetModal: "solicitacao-negada" });
        } else {
            this.setState({ targetModal: "solicitacao-aceita" });
        }

        this.setState({ solicitacaoMsg: msg, showModal: true });
    }

    /**
     * Método que envia uma solicação para a API de acordo com a necessidade do usuário
     * @method onSubmit
     * @param { e } Object recebe o elemento clicado
     */
    onSubmit(e) {
        e.preventDefault();
        const { portaoSelected } = this.state;

        this.setState({ loadingSubmit: true });

        const dadosUsuario = Store.get("dataUser");
        const UUID = Store.get("UUID");
        const latitude = Store.get("latitude");
        const longitude = Store.get("longitude");

        const post = {
            email: dadosUsuario.email,
            senha: dadosUsuario.senha,
            id_config_ics: portaoSelected.value,
            latitude: latitude,
            longitude: longitude,
            imei: UUID,
        };

        //Busca na API uma lista de desbloqueio
        Utils.sendPost(post, URL_DESBOQUEIO).then((response) => {
            if (response.status === 409) this.props.history.push("/logout");

            this.setState({
                showModal: true,
                loadingSubmit: false,
            });

            this.exibeNotificacao(response.error, response.msg);
        });
    }

    /**
     * Método que auxilia na renderização dos modais
     * @method renderContentModal
     */
    renderContentModal() {
        const { targetModal, solicitacaoMsg } = this.state;

        if (targetModal === "solicitacao-negada") {
            return (
                <div className="message row align-items-center justify-content-center">
                    <div className="col-12">
                        <h3 className="title title-icon error text-center">
                            <span className="icon icon-fechar"></span> ERRO
                        </h3>
                        <span className="text text-center text-bold">
                            {solicitacaoMsg}
                        </span>
                    </div>
                </div>
            );
        }

        if (targetModal === "solicitacao-aceita") {
            return (
                <div className="message row align-items-center justify-content-center">
                    <div className="col-12">
                        <h3 className="title title-icon sucesso text-center">
                            <span className="icon icon-check"></span>{" "}
                            Solicitação realizada.
                        </h3>
                        <span className="text text-center text-bold">
                            {solicitacaoMsg}
                        </span>
                    </div>
                </div>
            );
        }
    }

    /**
     * Método que renderiza a página
     * @method render
     */
    render() {
        const { show, loadingSubmit, listDesbloqueios } = this.state;
        const classForm = loadingSubmit ? "form loading-form" : "form";
        const classDisabled =
            listDesbloqueios.length !== 0
                ? "form-inner"
                : "form-inner form-disabled";

        return (
            <>
                <section
                    className={
                        show === true
                            ? "session session-acoes session-solicitar-desbloqueio show"
                            : "session session-acoes session-solicitar-desbloqueio"
                    }
                >
                    <div className="container">
                        <div className="modal-custom">
                            <header className="modal-heading">
                                <h3 className="modal-title">
                                    <span className="icon icon-acoes"></span>{" "}
                                    AÇÕES
                                </h3>
                                <Link to="/" className="link-modal home">
                                    <span className="icon icon-fechar"></span>
                                </Link>
                            </header>
                            <div className="modal-inner">
                                <form
                                    className={classForm}
                                    onSubmit={(e) => this.onSubmit(e)}
                                >
                                    <div className={classDisabled}>
                                        <div className="in in-icon">
                                            <span className="icon icon-desbloquear"></span>
                                        </div>
                                        <div className="in">
                                            <div className="form-group">
                                                <label className="text-bold">
                                                    Selecione o ponto de
                                                    desbloqueio:
                                                </label>
                                                <Combobox
                                                    id="portoes"
                                                    onCombobox={
                                                        this.toggleCombobox
                                                    }
                                                    showCombox={
                                                        this.state.showCombobox
                                                    }
                                                    comboSelected={
                                                        this.state
                                                            .portaoSelected
                                                    }
                                                >
                                                    <ComboboxItem
                                                        value=""
                                                        label="Selecione"
                                                        selected={
                                                            this.state
                                                                .portaoSelected
                                                                .value === ""
                                                                ? "true"
                                                                : "false"
                                                        }
                                                        onSelectItem={() =>
                                                            this.selectItem(
                                                                "",
                                                                "Selecione"
                                                            )
                                                        }
                                                    />

                                                    {listDesbloqueios.map(
                                                        (item, key) => (
                                                            <ComboboxItem
                                                                key={key}
                                                                value={
                                                                    item.id_config_ics
                                                                }
                                                                label={
                                                                    item.nome
                                                                }
                                                                selected={
                                                                    this.state
                                                                        .portaoSelected
                                                                        .value ===
                                                                    item.id_config_ics
                                                                        ? "true"
                                                                        : "false"
                                                                }
                                                                onSelectItem={() =>
                                                                    this.selectItem(
                                                                        item.id_config_ics,
                                                                        item.nome
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Combobox>
                                            </div>
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <Link
                                                            to="/"
                                                            className="btn btn-full btn-dark"
                                                        >
                                                            CANCELAR
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <button
                                                            type="submit"
                                                            disabled={
                                                                this.state
                                                                    .portaoSelected
                                                                    .value ===
                                                                ""
                                                                    ? "disabled"
                                                                    : ""
                                                            }
                                                            className={
                                                                this.state
                                                                    .portaoSelected
                                                                    .value ===
                                                                ""
                                                                    ? "btn btn-full disabled"
                                                                    : "btn btn-full"
                                                            }
                                                        >
                                                            ENVIAR
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal
                    id="modal-message"
                    className="modal-small modal-message"
                    show={this.state.showModal}
                    onClose={this.toggleModal}
                >
                    {this.renderContentModal()}
                </Modal>
            </>
        );
    }
}

export default SolicitarDesbloqueio;
