import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Store from 'store';

import moment from 'moment';
import 'moment/locale/pt-br';

import { URL_LISTA_OCORRENCIAS } from '../../../api';

import { Utils } from '../../../helpers';

import ScrollArea from 'react-scrollbar';

import ImageSemOcorrencia from '../../../images/semOcorrencias.png';

import './scss/QualityComVoce.scss';
import './scss/Ocorrencias.scss';


/**
 * Ocorrencias
 * 
 * Página que lista as ocorrências filtradas pelo o usuário.
 * 
 */
class Ocorrencias extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false,
      loadingPage: true,
      nomeCliente: '',
      listaOcorrencias: []
    }
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  async componentDidMount(){

    document.title = "Ocorrências - Quality com você | QNET";

    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const { match: { params } } = this.props;
    const arrListaOcorrencias = [];  

    const post = {
      'email': dadosUsuario.email,
      'senha': dadosUsuario.senha,
      'id_cliente': params.id_clinete,
      'data': params.data,
      'imei': UUID,
    };
    
    // Busca na API uma listagem de ocorrência de acordo do os parametros
    await Utils.sendPost(post, URL_LISTA_OCORRENCIAS)
    .then(
      (response) => { 
             
        if( response.status === 409 ){
          this.props.history.push('/logout');
          return;
        }
        
        if( response.error === true )
          return;

        const ocorrencias = response.ocorrencias;

        if( ocorrencias ){
          for( let ocorrencia of ocorrencias ){
            const formatData = moment(ocorrencia.dh_ocorrencia).format("LLL");

            arrListaOcorrencias.push({
              'id_ocorrencia': ocorrencia.id_ocorrencia,
              'dh_ocorrencia': formatData,
              'texto_ocorrencia': ocorrencia.texto_ocorrencia
            });
          }

          this.setState({ 
            listaOcorrencias: arrListaOcorrencias,
            nomeCliente: ocorrencias.length > 0 ? ocorrencias[0].nome_cliente : "",
            show: true,
            loadingPage: false
          });
        }
        
      }
    );

  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {

    const { nomeCliente, listaOcorrencias, loadingPage } = this.state;

    if( loadingPage ){
      return (
        <section className={this.state.show ? "session session-quality-com-voce session-filtros show" : "session session-quality-com-voce session-filtros"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS</h3>
                  <div className="nav-opcoes">
                    <Link to="/quality-com-voce" className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                    <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                  </div>
                </header>                                  
                <div className="modal-inner"></div>
                <div className="spinner"></div> 
              </div>
          </div>
        </section>
      );
    }

    if( listaOcorrencias.length === 0 ){
      return (
        <section className={this.state.show ? "session session-quality-com-voce session-filtros show" : "session session-quality-com-voce session-filtros"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS</h3>
                  <div className="nav-opcoes">
                    <Link to="/quality-com-voce" className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                    <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                  </div>
                </header>                   
                <div className="modal-inner">
                  <div className="sem-ocorrencias">
                    <img src={ ImageSemOcorrencia } className="img-fluid" alt="Não existe ocorrências" />
                    <p className="text text-center">Não existe ocorrências</p>
                  </div>
                </div>
              </div>
          </div>
        </section>
      );
    }
    
    return (
      <section className={this.state.show ? "session session-quality-com-voce session-filtros show" : "session session-quality-com-voce session-filtros"}>
        <div className="container">
            <div className="modal-custom">
              <header className="modal-heading">
                <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS <span className="nome-cliente">/ { nomeCliente }</span></h3>
                <div className="nav-opcoes">
                  <Link to="/quality-com-voce" className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                  <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                </div>
              </header>                   
              <div className="modal-inner">
                <ScrollArea
                  speed={0.8}
                  className="area"
                  contentClassName="content"
                  horizontal={false}>

                    <ul className="list">

                      {listaOcorrencias.map(( item, key ) => (
                        <li key={ key }>
                          <Link to={`/quality-com-voce/detalhes/ocorrencias/${ item.id_ocorrencia }`} className="inner">
                            <div className="in">
                              <span className="text">{ item.texto_ocorrencia }</span>
                            </div>
                            <div className="in">
                              <span className="text">{ item.dh_ocorrencia }</span>
                              <div className="view">
                                <span className="icon icon-visualizar"></span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))}
                      
                    </ul>
                </ScrollArea>
              </div>
            </div>
        </div>
      </section>
    );
  }
}

export default Ocorrencias;