import React, { Component } from 'react';

import Routes from './Routes';

import Background from './images/bg.jpg';

import ScrollArea from 'react-scrollbar';

import './styles/scss/App.scss';

const sectionStyle = {
  backgroundImage: `url(${Background})`
}

/**
 * App
 * 
 * Classe que monta toda a estrura do site.
 * 
 */
class App extends Component {
  
  /**
   * Método que renderiza a estrutura do site
   * @method render
   */
  render() {
    return (
      <main className='app' style={ sectionStyle }>
        <ScrollArea
            speed={0.8}
            className="area"
            contentClassName="content"
            horizontal={false}
            >
            <Routes />
        </ScrollArea>
      </main>
    );
  }
}

export default App;