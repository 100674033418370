import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import Store from 'store';
import { URL_LISTA_ROTEIROS } from '../../../api';
import { Utils } from '../../../helpers';

import ImageSemOcorrencia from '../../../images/semOcorrencias.png';

import './scss/Checklist.scss';

/**
 * Checklist
 * 
 * Página onde é exibido todos os checklist do usuário. 
 * 
 */
class Checklist extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    let checklists = [];
    checklists['count'] = 0;

    this.state = {
      show: false,
      loadingPage: true,
      count: 0,
      checklist: []
    }
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  async componentDidMount(){
    document.title = "Checklist | QNET";
    this.setState({ show: true });

    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    let checklists = [];
    
    const post = {
      'email': dadosUsuario.email,
      'imei':UUID,
    };

    //Busca na API uma listagem de checklist
    /*await Utils.sendPost( post, URL_LISTA_CHECKLIST )
    .then(
      (response) => {
        if( response.status === 409 )
          this.props.history.push('/logout');

        if( response.error === true )
          return;
          
        if( typeof response.checklists !== 'undefined' ){
          checklists['lista_checklist'] = response.checklists;
          this.setState({ count: ++this.state.count });
        }
      }
    );*/

    //Busca na API uma listagem de roteiros
    await Utils.sendPost( post, URL_LISTA_ROTEIROS )
    .then(
      (response) => {
        
        if( response.status === 409 ){
          this.props.history.push('/logout');
          return;
        }
        
        if( response.error === true )
          return;

        if( response.lista_roteiro.length > 0 ){
          checklists['lista_roteiro'] = response.lista_roteiro;
          this.setState({ count: ++this.state.count });
        }
      }
    );
    this.setState({ checklist: checklists, loadingPage: false });
  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {

    const { loadingPage, checklist, count } = this.state;

    if( loadingPage ){
      return (
        <section className={this.state.show ? "session session-checklist show" : "session session-checklist"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-ocorrencias"></span> OCORRÊNCIAS</h3>
                  <div className="nav-opcoes">
                    <Link to="/quality-com-voce" className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                    <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                  </div>
                </header>                                  
                <div className="modal-inner"></div>
                <div className="spinner"></div> 
              </div>
          </div>
        </section>
      );
    }

    if( count === 0 ){
      return (
        <section className={this.state.show ? "session session-checklist show" : "session session-checklist"}>
          <div className="container">
            <div className="modal-custom">
              <header className="modal-heading">
                <h3 className="modal-title"><span className="icon icon-celular"></span> CHECKLIST</h3>
                <Link to="/" className="link-modal"><span className="icon icon-fechar"></span></Link>
              </header>                   
              <div className="modal-inner"> 
                <div className="sem-checklist">
                  <img src={ ImageSemOcorrencia } className="img-fluid" alt="Não existe checklists" />
                  <p className="text text-center">Não existe checklists para esse cliente</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return (
      <section className={this.state.show ? "session session-checklist show" : "session session-checklist"}>
        <div className="container">
            <div className="modal-custom">
              <header className="modal-heading">
                <h3 className="modal-title"><span className="icon icon-celular"></span> CHECKLIST</h3>
                <Link to="/" className="link-modal"><span className="icon icon-fechar"></span></Link>
              </header>                   
              <div className="modal-inner"> 
                <ScrollArea
                  speed={0.8}
                  className="area"
                  contentClassName="content"
                  horizontal={false}
                  >
                    
                    <ul className="list">                      

                      { checklist.lista_roteiro && (
                        checklist.lista_roteiro.map((item, key) => (
                          <li key={ key }>
                            <Link to={ `/checklist/detalhes/roteiro/${ item.id_config_ics }` } className="inner">
                              <div className="in">
                                <span className="text">{ item.roteiro }</span>
                              </div>
                              <div className="in">
                                <div className="view">
                                  <span className="icon icon-visualizar"></span>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))
                      )}
                      
                    </ul>

                </ScrollArea>
              </div>
            </div>
        </div>
      </section>
    );
  }
}

export default Checklist;