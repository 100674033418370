import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import ScrollArea from 'react-scrollbar';

import { Modal } from '../../../componets/Modal';

import Store from 'store';
import { URL_ITEM_ROTEIRO, URL_SALVA_ROTEIRO } from '../../../api';
import { Utils } from '../../../helpers';

import './scss/Checklist.scss';
import './scss/Detalhes.scss';

/**
 * Detalhes
 * 
 * Página responsável por exibir os detalhes do checklist. 
 * 
 */
class Detalhes extends Component {

  /**
   * Método que é executado quando a classe é carregada
   * @method constructor
   * @param { props } Object recebe as proiedades do componentes
   */
  constructor( props ){
    super( props );

    this.state = {
      show: false,
      showModal: false,
      loadingPage: true,
      loadingSubmit: false,
      titleRoteiro: "",
      solicitacaoMsg: "",
      itens: [],
      data: []
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.hendleChange = this.hendleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Método que é executado logo após o componete ser montado
   * @method componentDidMount
   */
  async componentDidMount(){
    document.title = "Detalhes - Checklist | QNET";

    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const { match: { params } } = this.props

    if ( params.item === "roteiro" ) {

      const post = {
        'email': dadosUsuario.email,
        "id_config_ics": params.id,
        'imei': UUID,
      };

      // Busca na API os detalhes item do roteiro
      await Utils.sendPost( post, URL_ITEM_ROTEIRO )
      .then(
        (response) => {

          if( response.status === 409 ){
            this.props.history.push('/logout');
            return;
          }
          
          if( response.error === true )
            return;

          this.setState({ titleRoteiro: response.roteiro, itens: response.itens, loadingPage: false })
        }
      );
    }

    this.setState({ 
      show: true
    });

  }

  /**
   * Método que exibe ou esconde o modal
   * @method toggleModal
   */
  toggleModal(){
    this.setState({
      showModal: !this.state.showModal
    });    
  }

  /**
   * Método seta os campos do formulário no state data
   * @method onSubmit
   * @param { e } Object recebe o elemento atualizado
   */
  hendleChange( e ) {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  }

   /**
   * Método que auxilia na exibição das notificações de acordo com o parâmetro passado
   * @method exibeNotificacao
   * @param { errorSolicitacao } Boolean recebe verdadeiro ou falso
   * @param { msg } String recebe uma string com a mensagem retornada da api
   */
  exibeNotificacao( errorSolicitacao, response ){

    if( errorSolicitacao ){
      this.setState({ targetModal: "solicitacao-negada" });
    }
    else {
      this.setState({ targetModal: "solicitacao-aceita" });
    }

    this.setState({ solicitacaoMsg: response , showModal: true });

  }

  /**
   * Método que envia uma solicação para a API de acordo com a necessidade do usuário
   * @method onSubmit
   * @param { e } Object recebe o elemento clicado
   */
  onSubmit( e ) {
    e.preventDefault();
    const { data } = this.state;
    const dadosUsuario = Store.get('dataUser');
    const UUID = Store.get('UUID');
    const latitude = Store.get('latitude');
    const longitude = Store.get('longitude');
    const { match: { params } } = this.props
    let itemsPost = [[]];

    this.setState({ loadingSubmit: true });

    for (let field in data) { 
      let item_ocorrencia = field.split('_').pop();
      itemsPost[0].push({'id_config_ics_item' :item_ocorrencia, 'resposta': data[field]});
    };

    let post = {
      'email': dadosUsuario.email,
      'imei': UUID,
      'id_config_ics': params.id,
      'id_ocorrencia_qnet':0,
      'id_ocorrencia_qnet_checklist':0,
      'itens':JSON.stringify(itemsPost),
      'latitude': latitude,
      'longitude': longitude
    }

    //Envia para API um roteiro
    Utils.sendPost( post, URL_SALVA_ROTEIRO )
    .then(
      (response) => {
        this.setState({ loadingSubmit: false });
        this.refs.form.reset();
        this.exibeNotificacao( response.error, response.msg )
      }
    );

  }

  /**
   * Método que auxilia na renderização dos modais
   * @method renderContentModal
   */
  renderContentModal() {
    const { targetModal, solicitacaoMsg } = this.state;

    if( targetModal === "solicitacao-negada" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title title-icon error text-center"><span className="icon icon-fechar"></span> ERRO</h3>
            <span className="text text-center text-bold">{ solicitacaoMsg }</span>
          </div>
        </div>
      );
    }

    if( targetModal === "solicitacao-aceita" ){
      return (
        <div className="message row align-items-center justify-content-center">
          <div className="col-12">
            <h3 className="title title-icon sucesso text-center"><span className="icon icon-check"></span> Solicitação realizada.</h3>
            <span className="text text-center text-bold">{ solicitacaoMsg }</span>
          </div>
        </div>
      );
    }
  }

  /**
   * Método que renderiza a página
   * @method render
   */
  render() {

    const { loadingPage, show, titleRoteiro, itens, loadingSubmit, showModal } = this.state;
    const classForm = loadingSubmit ?  "form loading-form" : "form";

    if( loadingPage ){
      return (
        <section className={ show ? "session session-checklist session-detalhes show" : "session session-checklist session-detalhes"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-celular"></span> CHECKLIST</h3>
                  <div className="nav-opcoes">
                    <Link to="/checklist" className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                  </div>
                </header>                                  
                <div className="modal-inner"></div>
                <div className="spinner"></div> 
              </div>
          </div>
        </section>
      );
    }

    return (
      <>
        <section className={ show ? "session session-checklist session-detalhes show" : "session session-checklist session-detalhes"}>
          <div className="container">
              <div className="modal-custom">
                <header className="modal-heading">
                  <h3 className="modal-title"><span className="icon icon-celular"></span> CHECKLIST</h3>
                  <div className="nav-opcoes">
                    <Link to="/checklist" className="link-modal prev"><span className="icon icon-voltar"></span></Link>
                    <Link to="/" className="link-modal home"><span className="icon icon-fechar"></span></Link>
                  </div>
                </header>                   
                <div className="modal-inner">
                  <ScrollArea
                      ref="scrollModal"
                      speed={0.8}
                      className="area"
                      contentClassName="content"
                      horizontal={false}>
                      
                      <form method="POST" ref="form" className={ classForm } onSubmit={ (e) => this.onSubmit(e) } encType="multipart/form-data">
                        
                        <div className="row">
                          <div className="col-12">
                            <h3 className="title-roteiro">{ titleRoteiro }</h3>
                          </div>
                        </div>

                        {itens && (
                          <div className="row">
                            {itens.map( (item, key) => (
                              <div className="col-12 col-md-6 form-group" key={ key }>
                                <label>{ item.texto_item }{ item.requerido === '1' ? '*' : '' }</label>

                                {item.formato !== "sim-nao" && (
                                  <input type="text" 
                                    name={ `field_${item.item_ocorrencia}` } 
                                    className={ item.requerido === '1' ? 'form-control required': 'form-control' }
                                    required={ item.requerido === '1' ? true : false }
                                    placeholder="Digite aqui" 
                                    autoComplete="off" 
                                    onChange={ (e) => this.hendleChange(e) } />
                                )}

                                {item.formato === "sim-nao" && (
                                  <div className="custom-radio">
                                    <label>
                                      <input type="radio" 
                                        name={ `field_${item.item_ocorrencia}` } 
                                        value="S" 
                                        className={ item.requerido === '1' ? 'form-control required': 'form-control' }
                                        required={ item.requerido === '1' ? true : false }
                                        onChange={ (e) => this.hendleChange(e) } />
                                      <span>Sim</span>
                                    </label>
                                    <label>
                                      <input type="radio" 
                                        name={ `field_${item.item_ocorrencia}` } 
                                        value="N" 
                                        className={ item.requerido === '1' ? 'form-control required': 'form-control' } 
                                        required={ item.requerido === '1' ? true : false }
                                        onChange={ (e) => this.hendleChange(e) } />
                                      <span>Não</span>
                                    </label>
                                  </div>
                                )}                                
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="form-group">
                          <button type="submit" className="btn btn-full">ENVIAR CHECKLIST</button>
                        </div>
                      </form>

                  </ScrollArea>
                </div>
              </div>
          </div>
        </section>
        <Modal id="modal-error-upload" className="modal-small modal-error-upload" show={ showModal } onClose={ this.toggleModal }>
          { this.renderContentModal() }
        </Modal>
      </>
    );
  }
}

export default Detalhes;